import type { FC } from 'react';
import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { useMutation } from 'react-apollo';

import Popup from '@atlaskit/popup';
import { Pressable, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import AkSpinner from '@atlaskit/spinner/spinner';
import Button from '@atlaskit/button/standard-button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import {
	VIEW_PAGE_BYLINE_FORGE_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { fg } from '@confluence/feature-gating';
import type {
	ForgeUIExtensionDataType,
	ForgeUIContentBylineItemExtensionType,
} from '@confluence/forge-ui';
import {
	ByLineForgeApp as ByLineForgeAppOriginal,
	extensionTitle,
	ContentChangeListener,
	useInlineDialogCloseManager,
	useWorkspaceARI,
	FORGE_MODULE_BYLINE,
	getLocalId,
	getContentType,
	getAppId,
	getModuleKey,
	ForgeKeyboardShortcut,
	ForgeKeyboardShortcutVisualizer,
} from '@confluence/forge-ui';
import { getAGGClient } from '@confluence/graphql';
import { usePageState } from '@confluence/page-context';
import { useSessionData } from '@confluence/session-data';
import { getLogger } from '@confluence/logger';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { getSpaceType, useSpaceIdentifiers } from '@confluence/space-utils';

import { ByLineDynamicPropertiesMutation } from './ByLineDynamicPropertiesMutation.aggraphql';
import AddonIcon from './AK_AddonIcon.svg';
import { useMemoizedObj } from './byLineUtils';

const EXPERIENCE_NAME = VIEW_PAGE_BYLINE_FORGE_EXPERIENCE;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentWrapper = styled.div({
	padding: token('space.250'),
	maxWidth: '500px',
	maxHeight: '500px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ByLineForgeAppHeaderWrapper = styled.span<{
	isBadgeStyle?: boolean;
	isHidden: boolean;
	hideDot: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		display: props.isHidden ? 'none' : 'inline-block',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginRight: props?.isBadgeStyle
			? token('space.0')
			: props.hideDot
				? token('space.200')
				: token('space.050'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:before': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			content: props.isHidden || props.hideDot ? undefined : '"•"',
			display: 'inline-block',
			color: token('color.text.subtle'),
			padding: `0 ${token('space.100')} 0 0`,
			/* for IE <= 11 */
			textDecoration: 'none',
		},
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			textDecoration: props.isBadgeStyle ? 'none' : 'underline',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			textDecorationLine: props.isBadgeStyle ? 'none' : 'underline',
			cursor: 'pointer',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:before': {
				/* for IE <= 11 */
				textDecoration: 'none',
			},
		},
	}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	height: '14px',
	width: '14px',
	display: 'inline-block',
	verticalAlign: 'text-bottom',
	padding: `0 ${token('space.050')} 0 0`,
	color: token('color.icon'),
});

const badgeStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: 'border.radius',
	display: 'flex',
	font: 'font.body.small',
	color: 'color.text.subtle',
	gap: 'space.050',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '16px',
	marginRight: 'space.0',
	padding: 'space.050',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		textDecoration: 'none',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

export type ByLineDPDataInvokeExtensionErrorsType = {
	message?: string;
	extensions?: {
		statusCode?: number;
	};
}[];

export type ByLineDPDataType = {
	data: {
		invokeExtension: {
			success: boolean;
			response?: {
				body: {
					title?: string;
					tooltip?: string;
					icon?: string;
				};
			};
			errors?: ByLineDPDataInvokeExtensionErrorsType;
		};
	};
	extensions?: any;
};

type ContextPayloadType = {
	cloudId: string;
	localId: string;
	extension: ForgeUIExtensionDataType;
};

type ByLineDPVarsType = {
	input: {
		entryPoint: string;
		extensionId?: string;
		extensionDetails?: any;
		contextIds: string[];
		payload: {
			context: ContextPayloadType;
		};
	};
};

const ByLineForgeAppTrigger = ({
	app,
	reference,
	defineTriggerRef,
	toggleClose,
	isOpen,
	extensionData,
	isHidden,
	hideDot,
	appTitle,
	setAppTitle,
}: {
	app: ForgeUIContentBylineItemExtensionType;
	reference: React.Ref<HTMLElement> | null;
	defineTriggerRef: (ref: React.Ref<HTMLElement> | null) => (node: HTMLElement | null) => void;
	toggleClose: () => void;
	isOpen: boolean;
	extensionData: ForgeUIExtensionDataType;
	isHidden: boolean;
	hideDot: boolean;
	appTitle: string;
	setAppTitle: (string) => void;
}) => {
	const appHasDynamicProperties: boolean = !!app.properties.dynamicProperties;
	const { cloudId } = useSessionData();
	const workspaceARI = useWorkspaceARI();

	const [appIcon, setAppIcon] = useState<string | null>(app.properties.icon || null);
	const [appTooltip, setAppTooltip] = useState<string | null>(app.properties.tooltip || null);
	const [isLoading, setIsLoading] = useState<boolean>(appHasDynamicProperties);

	const [bylineDynamicPropertiesMutation] = useMutation<ByLineDPDataType, ByLineDPVarsType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ByLineDynamicPropertiesMutation,
		{
			client: getAGGClient(),
		},
	);

	const fetchDynamicProperties: () => Promise<void> = useCallback(async () => {
		const extensionId = app.id;
		const localId = getLocalId(extensionId);
		const contextPayload: ContextPayloadType = {
			cloudId,
			localId,
			extension: extensionData,
		};

		const result = await bylineDynamicPropertiesMutation({
			variables: {
				input: {
					entryPoint: 'dynamicProperties',
					contextIds: [workspaceARI],
					extensionId,
					payload: {
						context: contextPayload,
						...contextPayload, // TODO: This is temporary for Forge apps' backward compatibility
					},
				},
			},
		}).catch((e) => {
			return {
				data: {
					invokeExtension: {
						success: false,
						errors: [
							{
								message: e,
							},
						],
					},
				},
			};
		});

		setIsLoading(false);

		// @ts-expect-error The types here are a bit messy. Typegen for AGG should help solve that
		const { response, success } = result.data?.invokeExtension;
		// @ts-expect-error The types here are a bit messy. Typegen for AGG should help solve that
		const errors: ByLineDPDataInvokeExtensionErrorsType = result.data?.invokeExtension.errors;

		if (!success) {
			const logger = getLogger('by-line-apps');

			const errorMessages: string = errors.map((error) => error.message).join(' ');

			// prints error to aid forge developer && allow Ecosystem to track these errors
			logger.error`Failed to fetch dynamic properties for ByLine Forge app: ${app.id} with error: ${errorMessages}`;

			if (errorMessages !== 'User consent required') {
				getMonitoringClient().submitError(
					new Error(
						`Failed to fetch dynamic properties for ByLine Forge app: ${app.id} with error: ${errorMessages}`,
					),
					{ attribution: Attribution.ECOSYSTEM },
				);
			}

			return;
		}

		if (response.body) {
			const {
				body: { title: dynamicPropsTitle, tooltip: dynamicPropsTooltip, icon: dynamicPropsIcon },
			} = response;

			dynamicPropsTitle && setAppTitle(extensionTitle(app, dynamicPropsTitle));
			dynamicPropsTooltip && setAppTooltip(dynamicPropsTooltip);
			dynamicPropsIcon && setAppIcon(dynamicPropsIcon);
		}
	}, [app, cloudId, extensionData, bylineDynamicPropertiesMutation, workspaceARI, setAppTitle]);

	useEffect(() => {
		// refetch for updates on popup.content close
		if (!isOpen && appHasDynamicProperties) {
			void fetchDynamicProperties();
		}
	}, [isOpen, appHasDynamicProperties, fetchDynamicProperties]);

	const ForgeAppButton = fg('cc_page_experiences_byline_badge_style') ? (
		<Pressable testId="byline-forge-app-badge" xcss={badgeStyles}>
			{appIcon && (
				<Image
					data-testid="byline-forge-app-image"
					src={appIcon}
					onError={(e) => {
						e.currentTarget.src = AddonIcon;
					}}
				/>
			)}
			{appTitle}
		</Pressable>
	) : (
		<Button
			appearance="subtle-link"
			spacing="none"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
			style={{ fontWeight: token('font.weight.regular') }}
			aria-haspopup="dialog"
		>
			{appIcon && (
				<Image
					data-testid="byline-forge-app-image"
					src={appIcon}
					onError={(e) => {
						e.currentTarget.src = AddonIcon;
					}}
				/>
			)}
			{appTitle}
		</Button>
	);

	const bylineForgeAppHeaderWrapper = (
		<ByLineForgeAppHeaderWrapper
			ref={defineTriggerRef(reference)}
			onClick={toggleClose}
			isHidden={isHidden}
			hideDot={hideDot}
			isBadgeStyle={fg('cc_page_experiences_byline_badge_style')}
			data-testid="forge-byline-header-wrapper"
		>
			{isLoading ? (
				<AkSpinner size="small" testId="byline-forge-app-trigger-loading-spinner" />
			) : (
				ForgeAppButton
			)}
		</ByLineForgeAppHeaderWrapper>
	);

	return app.properties?.keyboardShortcut?.accelerator ? (
		<ForgeKeyboardShortcutVisualizer module={app} tooltip={appTooltip ?? ''}>
			{bylineForgeAppHeaderWrapper}
		</ForgeKeyboardShortcutVisualizer>
	) : (
		<Tooltip tag="span" content={appTooltip}>
			{bylineForgeAppHeaderWrapper}
		</Tooltip>
	);
};

type ByLineForgeAppProps = {
	app: ForgeUIContentBylineItemExtensionType;
	contentId: string;
	isHidden?: boolean;
	setShowAll?: (boolean) => void;
	hideDot?: boolean;
};

export const ByLineForgeApp: FC<ByLineForgeAppProps> = ({
	app,
	contentId,
	isHidden = false,
	setShowAll = () => {},
	hideDot = false,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { activationId } = useSessionData();
	const isLivePage = useIsCurrentPageLive();

	const [isOpen, setIsOpen] = useState(false);
	const [popupRef, setPopupRef] = useState<HTMLElement | null>(null);
	const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
	const [appTitle, setAppTitle] = useState<string>(extensionTitle(app));

	const [{ routeName, spaceKey: spaceAlias }] = usePageState();
	const { spaceId, spaceKey } = useSpaceIdentifiers();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const extensionData: ForgeUIExtensionDataType = useMemoizedObj({
		type: FORGE_MODULE_BYLINE,
		content: {
			id: contentId,
			type: getContentType(routeName),
			subtype: isLivePage ? 'live' : null,
		},
		space: {
			id: spaceId,
			key: spaceKey || spaceAlias,
		},
	});

	const defineContentRef = (node: HTMLElement | null) => {
		setPopupRef(node);
	};

	const defineTriggerRef = (ref) => (node: HTMLElement | null) => {
		setTriggerRef(node);
		ref(node);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const toggleClose = useCallback(() => {
		// Run experience only while opening the popup
		!isOpen &&
			experienceTracker.start({
				name: EXPERIENCE_NAME,
				id: contentId,
				timeout: 10000,
			});

		setIsOpen(!isOpen);
	}, [setIsOpen, isOpen, experienceTracker, contentId]);

	const onInitialRender = useCallback(() => {
		experienceTracker.succeed({
			name: EXPERIENCE_NAME,
		});
		const appId = getAppId(app);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'invoked',
				actionSubject: 'forgeExtension',
				actionSubjectId: appId,
				containerType: 'space',
				containerId: spaceId,
				objectType: getContentType(routeName),
				objectId: contentId,
				source: FORGE_MODULE_BYLINE,
				attributes: {
					activationId,
					appId,
					moduleType: FORGE_MODULE_BYLINE,
					moduleKey: getModuleKey(app),
					spaceType: getSpaceType(spaceAlias),
					spaceId,
				},
			},
		}).fire();
	}, [
		activationId,
		app,
		contentId,
		routeName,
		createAnalyticsEvent,
		experienceTracker,
		spaceAlias,
		spaceId,
	]);

	const bridge = useMemo(
		() => ({
			onClose,
		}),
		[onClose],
	);

	useInlineDialogCloseManager({ onClose, popupRef, triggerRef });

	if (!spaceKey) {
		return null;
	}

	return (
		<>
			<Popup
				testId="forge-action-popup"
				role="dialog"
				content={({ update: scheduleUpdate }) => {
					return (
						<ContentWrapper ref={defineContentRef}>
							<ContentChangeListener onUpdate={scheduleUpdate} popupRef={popupRef}>
								<ByLineForgeAppOriginal
									app={app}
									extensionData={extensionData}
									onInitialRender={onInitialRender}
									onTearDown={onClose}
									bridge={bridge}
								/>
							</ContentChangeListener>
						</ContentWrapper>
					);
				}}
				label={appTitle}
				isOpen={isOpen}
				placement="bottom-start"
				trigger={({ ref }) => {
					return (
						<ByLineForgeAppTrigger
							app={app}
							appTitle={appTitle}
							setAppTitle={setAppTitle}
							reference={ref}
							isOpen={isOpen}
							defineTriggerRef={defineTriggerRef}
							toggleClose={toggleClose}
							extensionData={extensionData}
							isHidden={isHidden}
							hideDot={hideDot}
						/>
					);
				}}
			/>
			<ForgeKeyboardShortcut
				module={app}
				action={() => {
					setShowAll(true);
					setIsOpen(true);
				}}
			/>
		</>
	);
};
